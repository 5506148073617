.page_header {
  background-color: #fff;
}

.markerIcon {
  background-image: url(../../../assets/wagon.svg);
  font-size: 2em;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;
}

.markerSpan {
  background-color: white;
  font-size: 0.6em;
  border-radius: 15px;
  width: 15px;
  height: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
}
