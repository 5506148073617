.page_header {
  background-color: white;
}

.content {
  background-color: white;
}

.ant-form-inline .ant-form-item {
  -ms-flex: none;
  flex: none;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  /* margin-right: 16px; */
  margin-bottom: 0;
}

.d-flex {
  ul {
    display: flex;
  }
}
