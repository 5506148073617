.message_list {
  padding: 15px 30px;
  margin: 5px;
  //overflow: auto;
  //max-width: 800px;
}

.compose {
  border: none;
  background-color: hsla(0, 0%, 100%, 0.8);
  -webkit-backdrop-filter: blur(20px);
  backdrop-filter: blur(20px);

  padding: 10px;
  display: flex;
  align-items: center;
  background: #fff;
  border-top: 1px solid #eeeef1;
  position: fixed;
  width: calc(100% - 100px);
  bottom: 0;
}
