.logo {
  align-items: center;
  position: inherit;
  height: var(--header-height);
  //width: 335px;
  background-color: white;
  z-index: 1;
}

.logo > span {
  cursor: pointer;
  height: var(--header-height);
}

.logo > span > img {
  cursor: pointer;
  height: var(--header-height);
  /*-webkit-box-shadow: 4px 0px 3px 1px rgba(30, 30, 30, 0.15);
  -moz-box-shadow: 4px 0px 3px 1px rgba(30, 30, 30, 0.15);
  box-shadow: 4px 0px 3px 1px rgba(30, 30, 30, 0.15);*/
}

:global(.ant-layout-sider-trigger) {
  position: fixed;
  bottom: 0;
  z-index: 1;
  height: 40px;
  color: #fff;
  line-height: 8px;
  text-align: center;
  background: #515769;
  cursor: pointer;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
}

.logoMin {
  width: 79px;
}

.logoMax {
  width: 100%;
  padding-left: 24px;
  padding-right: 62px;
}
