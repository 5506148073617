.floatlabel {
  position: relative;
  //min-height: 52px !important;
  border: 1px solid #dedede;
}

.label {
  position: absolute;
  left: 16px;
  top: 14px;
  transition: 0.2s ease all;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #808c9a;
  pointer-events: none;
}

.labelfloat {
  position: absolute;
  //pointer-events: none;
  left: 16px;
  transition: 0.2s ease all;
  top: 6px;
  font-style: normal;
  font-weight: 400;
  font-size: 12px !important;
  line-height: 16px;
  color: #808c9a;
  white-space: nowrap;
}

.inputpassword {
  width: 100%;
  height: 52px !important;
}

.inputpassword > input {
  padding-top: 16px !important;
  padding-left: 4px !important;
}

.input {
  width: 100%;
  height: 52px !important;
  padding: 16px 0 0 16px;
  margin-top: 8px !important;
}

.inputwithaddon {
  width: 100%;
  height: 52px !important;
}

.inputwithaddon > span > input {
  height: 52px !important;
  padding: 16px 0 0 16px;
}

.inputwithaddon > span > span {
  border: none !important;
  background: transparent;
}

.inputnumber {
  width: 100%;
  height: 52px !important;
  padding: 8px 0 0 8px;
}

.inputnumber > div > input {
  height: 52px !important;
  padding-top: 15px;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 20px !important;
}

.select {
  width: 100%;
  min-height: 52px !important;
}
.select > div {
  //height: 52px !important;
  padding: 11px 0 0 16px !important;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  margin: -1px;
}
.select > div > span > input {
  height: 20px !important;
  margin-top: 24px !important;
  padding: 0 0 0 4px !important;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
}
.select > div > div > div > span {
  height: 20px !important;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px !important;
  margin-top: 12px !important;
}

.select > div > div > div > div > input {
  margin-top: 4px !important;
}

.picker {
  height: 52px !important;
  width: 100%;
}
.picker > div > input {
  height: 52px !important;
  padding: 16px 0 0 6px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 20px !important;
}
