.container {
  height: 100vh;
  //background-image: url("assets/bg-image.jpg");
  background-position: center;
  background-size: cover;
  //filter: grayscale(80%);
  //background-color: rgba(10, 10, 10, 50);
}

.content {
  margin-right: auto;
  margin-left: auto;
  //margin-bottom: auto;
  //display: flex;
  //justify-content: center;
}
