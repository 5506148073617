.site_tag_plus {
  background: #fff;
  border-style: dashed;
}

.tag_list_icon {
  margin: 0 5px;
  cursor: pointer;
}

.tag_list_icons_block {
  float: right;
}

.color_selector {
  max-width: 240px;
}

.color_selector_item {
  margin: 2px;
}
