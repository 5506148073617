.message {
  display: flex;
  flex-direction: column;
}

.bubble_container {
  font-size: 14px;
  display: flex;
}

.title {
  font-size: 12px;
  color: darkgray;
}

.author {
  margin-right: 10px;
}

.time {
  font-size: 9px;
}

.bubble {
  margin: 1px 0;
  background: #ffffff;
  padding: 10px 15px;
  border-radius: 20px;
  max-width: 75%;
  word-wrap: break-word;
  white-space: pre;
  //border-top-left-radius: 2px;
  //border-bottom-left-radius: 2px;
  //border-top-right-radius: 20px;
  //border-bottom-right-radius: 20px;
}

.my_message {
  background-color: #cccccc;
  justify-content: right;
  margin: 10px;
  padding: 3px 5px;
}
