.loadingRegister {
  .ant-card-head-wrapper {
    align-items: baseline;
  }

  .summary-info {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-top: 15px;
  }
}

.loading-register--form-row {
  margin-top: 20px;
}

.loading-register--form-row--column > *:nth-child(1n + 2) {
  margin-top: 20px;
}
